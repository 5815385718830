body{
  background-color: #0d0d0d;
  color: white;
}

.dashboard-content{
  --background: #0d0d0d;
  --color: white;
}

.dashboard-card{
  background-color: #2a2a2a;
  border-radius: 5px;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 50px;
  padding: 50px;
}