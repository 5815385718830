.skills-image::part(image) {
    max-height: 347px;
    height: auto;
}

.skills-image-wrapper {
    display: flex;
    justify-content: center;
}


.products-container {
    min-height: 600px;
    width: 100vw;
    background-color: #F4F7FA;
    text-align: center;
    padding: 60px 0;
}

.why-us-container {
    padding-top: 40px;
}

.about {
    background: radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%) ,
    radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%) ,
    radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) ,
    radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145;
    padding-top: 56px;
}

.home-card {
    margin: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    padding: 64px var(--content-padding);
    border-radius: 32px;
    color: #fff;

    .heading-line {
        position: relative;
    }
    .slide {
        position: relative;
        bottom: -40px;
        color: rgba(255, 255, 255, 0);
        transition: bottom 2s ease-out, color 2s ease-out;
        &--in {
            color: #fff;
            bottom: 0;
        }
    }
}

h1 {
    font-weight: bolder !important;
}

@media screen and (min-width: 768px) {
    section h1 {
        font-size: 3.5rem;
    }
}


@media screen and (min-width: 992px) {
    .about {
        padding: 56px var(--content-padding);
        section h1 {
            width: 713px;
            margin: 0 auto;
        }
        .home-card {
            padding: 64px 0;
        }
    }
}
