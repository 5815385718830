ion-img {
  height: 200px;
}

.product {
  & * {
    text-align: left;
  }
  &--title {
    font-weight: bolder;
    color: #000;
    text-align: left;
    text-transform: uppercase;
  }
  &--description {
    color: #000;
    margin-top: 20px !important;
  }
  &--sections ion-item {
    color: rgb(64,67,118);
  }
}
