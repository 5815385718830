.footer {
  background: #1C0230;
  h4 {
    color: #fff;
  }
  * {
    color: #8e93c2
  }
}

.newsletter {
  &--email {
    ion-input {
      --padding-start: 8px !important;
      border-radius: 5px;
      background: #2a2753;
      input:focus {
        border: 1px solid var(--ion-color-danger);
        border-radius: 5px;
      }
    }
    ion-label {
      font-size: 0.6rem !important;
      span {
        color: var(--ion-color-danger);
      }
    }
  }
  &--agree {
    display: grid;
    ion-checkbox {
      --background-checked: var(--ion-color-danger);
    }

    ion-checkbox::part(container) {
      border: 2px solid #8e93c2;
    }
    ion-label {
      font-size: 0.8rem !important;
    }
  }
  ion-item {
    --padding-start: 0;
  }
}
