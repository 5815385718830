.spinner-container{
  position: absolute;
  width: 100%;
  top: 10%;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.spinner{
  background-color: #1f1f1f;
  padding: 5px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}